import { OffshoreCardArray } from "@/DummyData";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Container } from "reactstrap";
import {
  ExpertMindSub,
  ExpertMindsLeft,
  ExpertMindsMain,
  ExpertMindsRight,
  ExpertMindsRightSub,
  MindsLeftButton,
  MindsLeftH1,
  MindsLeftP,
  RightMain,
  RightMainH3,
  RightMainP
} from "../../StyledComponents/HomeStyled";

gsap.registerPlugin(ScrollTrigger);

export default function ExpertMind() {
  const ref = useRef(null);
  const arrowRef = useRef(null);
  const [hasInteracted, setHasInteracted] = useState(false);

  useEffect(() => {
    gsap.to(arrowRef.current, {
      x: 10,
      duration: 0.7,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });

    if (ref.current) {
      gsap.fromTo(
        ref.current,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: "power2.out",
          scrollTrigger: {
            trigger: ref.current,
            start: "top 80%",
            toggleActions: "play none none none",
          },
        }
      );
    }

    const enableImages = () => {
      setHasInteracted(true);
      removeEventListeners();
    };

    const removeEventListeners = () => {
      document.removeEventListener("mousemove", enableImages);
      document.removeEventListener("scroll", enableImages);
      document.removeEventListener("keydown", enableImages);
    };

    document.addEventListener("mousemove", enableImages);
    document.addEventListener("scroll", enableImages);
    document.addEventListener("keydown", enableImages);

    return removeEventListeners;
  }, []);

  return (
    <ExpertMindsMain className="!pb-0">
      <Container>
        <ExpertMindSub>
          <ExpertMindsLeft>
            <div
              className="textAaria sticky top-[90px] md:block flex flex-wrap justify-center"
              ref={ref}
            >
              <MindsLeftP>Expert Minds</MindsLeftP>
              <MindsLeftH1 className="md:text-start text-center">Why Choose IT Services India?</MindsLeftH1>
              <MindsLeftP className="ExpertMindsText md:text-start text-center">
                We know choosing the right partner for your <br></br>custom
                software development is key to achieving <br></br>success.
              </MindsLeftP>
              <MindsLeftButton>
                <a
                  href="/about-us"
                  target="_self"
                  className="h-btn btn dark-btn gap-[10px] common-btn-hover !mt-[30px]"
                >
                  About Us{" "}
                  <span ref={arrowRef}>
                    {/* <Icon icon="tabler:arrow-right" /> */}
                    <Image
                      src="/svgs/Right-Arrow-White.svg"
                      alt="Right-Arrow-White"
                      width={16}
                      height={16}
                      loading="lazy"
                      className="w-full "
                    />
                  </span>
                </a>
              </MindsLeftButton>
            </div>
          </ExpertMindsLeft>

          <ExpertMindsRight>
            <ExpertMindsRightSub>
              {OffshoreCardArray.map((m) => (
                <AnimationOnScroll
                  key={m.id}
                  duration="0.3"
                  animateIn="animate__fadeIn"
                  className="RightMain"
                  animateOnce={true}
                  offset={500}
                >
                  <RightMain>
                    {hasInteracted && (
                      <Image
                        src={m.img}
                        width={42}
                        height={42}
                        alt={m.title}
                        loading="lazy"
                      />
                    )}
                    <RightMainH3>{m.title}</RightMainH3>
                    <RightMainP>{m.content}</RightMainP>
                  </RightMain>
                </AnimationOnScroll>
              ))}
            </ExpertMindsRightSub>
          </ExpertMindsRight>
        </ExpertMindSub>
      </Container>
    </ExpertMindsMain>
  );
}
